// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import designIcon from "./images/design.svg"
import codeIcon from "./images/code.svg"
import pollIcon from "./images/bar_chart_black_48dp.svg"
import geoIcon from "./images/travel_explore_black_24dp.svg"
import chartIcon from "./images/insights_black_48dp.svg"
import spreadsheetIcon from "./images/spreadsheetIcon.png"
// Social Icon
import githubIcon from "./images/github.svg"
import codepenIcon from "./images/codepen.svg"
import dribbbleIcon from "./images/dribbble.svg"
import instagramIcon from "./images/instagram.svg"
import llama from "./images/1706367517_llama_in_style_of_Alfonso_Mucha_.png"
const url = "https://inspiring-bhabha-0e80f6.netlify.app/"
export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Silverlode Media",
  headerTagline: [
    //Line 1 For Header
    "Modern marketing",
    //Line 2 For Header
    "Real world analytics",
    //Line 3 For Header
    "Good vibes",
  ],
  //   Header Paragraph
  headerParagraph:
    "Be a part of Denver's flourishing revival. We want to help you enrich our city.",

  //Contact Email
  contactEmail: "silversoftwerks@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Jurassic", //Project Title - Add Your Project Title Here
      para: "Deforum render based of Jurassic Park script", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: "src/videos/Jurassic.20230825000740.mp4", //Project URL - Add Your Project Url Here
      type: "video",
      url: "",
    },
    {
      title: "The Spot", //Project Title - Add Your Project Title Here
      para: "The Spot film", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://lh3.googleusercontent.com/pw/AM-JKLXEKWx7VmDIQMuU_crZVZgNCNo0pWmaKc0Mr6Pceu-VG9sPuzIwt4TfceXoAvoPTTCFotdeFd7G6jwUH8ydspmukXuOt7jCjNGXbL9ETOnjA_rx3GYx6WElQekaw6l2jkzchgdphfbSRLYh86bmoqzsnQ=w918-h1222-no?authuser=0",
      //Project URL - Add Your Project Url Here
      url: "",
    },
    {
      title: "Mucha Llama", //Project Title - Add Your Project Title Here
      para: "AI Imagery", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: llama, //Project URL - Add Your Project Url Here
      url: llama,
    },
    {
      title: "Project Four", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://chetanverma.com/",
    },
    {
      title: "Project Five", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://chetanverma.com/",
    },
    {
      title: "Project Six", //Project Title - Add Your Project Title Here
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
      //Project URL - Add Your Project Url Here
      url: "http://chetanverma.com/",
    },

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "Founded by 2 brothers, Silverlode Media represtents a new form of grass roots marketing. Leveraging technology, creativity, and most importantly, fun, we aim to elevate artists, empower businesses, and enable patrons to easily connect.",
  aboutParaTwo: "QR code and hardlinking technology",
  aboutParaThree:
    "Multimedia capabilities including web, print, audio, graphic arts, photography, videography, audio/signal visualizers, VR, AR, and XR. With over 15 years of experience, our artists and software engineers help you develop campaigns that apeal to the next generation of consumers",
  aboutImage:
    "https://lh3.googleusercontent.com/_PLaE-l8c14nkdjHPh-D5kDyZKa3MRldKdtDxZGz38_OYwrpygIFFKYrjwXJaTuhFHNaKchBHOkrPVrZCUbGhKSWEWbREI5xf2qcbu7VKyvm7Aza8hPree9s3YzJwdpyOoL0WiVafsH4gWUEOLttsL8UadW6POXijeCe0UPNOXOvzd8gDx_PG0bcYnMN0ekTmqglSEILD84-CpvAtHyNOHkiAL3fEyo9xQMgKEdZaJX8HrmrcGsDyGzD9zzMqhB-Qd_D3JzK1QnVC3rhbZnmZmdxz1a81Tqr9y1iRmADtHzziiWtFt7xEwqkehW9te9P01QjyqSlJK3ILXx-IXYsKxlgf_VMfLhgfq8iVvaigvsYRHDzqcvpIThOT_Fq1uyN1UNZv5uoBiHF-UPX6n62-txAbGEiGK74Dmy6sZ4Ie4VcNvzN3pF2GkS7gcjZJi-__ovVt7CK__XBctkcHBfW3f80c4US2TDKSVkmmQBIMXLfKkBTnSU4tEOwQ1wkcueNBuHCmYj4DTDkwq88oQOMigl3g4iEa2sGVUONrn2VCc-VxMo6c0XUSSXsdCmaYW6xPOLEpBDILTqdwkF3M5h5jaUL7sV_RMezirDx9TZgoPpPsPGcLKxyaHnLPNGuxJzrrVwlOGs9r6ZwS2PvhiYh3t57GDwE-SuWI6QBOagckaVoi12hJj0fG64OI2qHs5hOs4vYrk4WBO2iUjHpQevv0Qtrpg=s0",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here
  // ADditional Services, Scan to Buy (tickets, orders, etc)
  skills: [
    {
      img: pollIcon,
      para: "Consumer Polling",
    },
    {
      img: chartIcon,
      para: "QR Code Scan Analytics",
    },
    {
      img: geoIcon,
      para: "Geographic Analytics",
    },
    {
      img: spreadsheetIcon,
      para: "Spreasheet Reports",
    },
    {
      img: jsIcon,
      para: "Web App Development",
    },
    {
      img: designIcon,
      para: "Media Design",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Reviews",
  promotionPara: "I saw that guy",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's enrich our town",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/silversoftwerks" },
    //     {
    //       img: codepenIcon,
    //       url: "https://www.codepen.com/",
    //     },
    {
      img: dribbbleIcon,
      url: "https://dribbble.com/silversoftwerks",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/silversoftwerks",
    },
  ],

  // End Contact Section ---------------
}
